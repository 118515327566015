<template>
  <v-card class="mb-5" v-if="totalSums.artikelListe">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" md="12" lg="12" class="pa-0">
        <v-card-title>Kostenvoranschlag</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left text-uppercase">Position</th>
                <th class="text-right text-uppercase">Menge</th>
                <th class="text-right text-uppercase">Einzelpreis</th>
                <th class="text-right text-uppercase">Netto</th>
                <th class="text-right text-uppercase">Mwst.</th>
                <th class="text-right text-uppercase">Brutto</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="article in totalSums.artikelListe" :key="article.id">
                <td>{{ article.titel }}</td>
                <td class="text-right">{{ article.artikelAnzahl }}</td>
                <td class="text-right">{{ convertToGermanFormat(article.einzelpreisNetto) }} {{ article.waehrung }}</td>
                <td class="text-right">{{ convertToGermanFormat(article.gesamtpreisNetto) }} {{ article.waehrung }}</td>
                <td class="text-right">{{ convertToGermanFormat(article.gesamtpreisMwst) }} {{ article.waehrung }}</td>
                <td class="text-right">{{ convertToGermanFormat(article.gesamtpreisBrutto) }} {{ article.waehrung }}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td><strong>Gesamt</strong></td>
                <td colspan="2"></td>
                <td class="text-right">{{ convertToGermanFormat(totalSums.zusammenfassung.nettoBetrag) }} {{ totalSums.artikelListe[0].waehrung }}</td>
                <td class="text-right">{{ convertToGermanFormat(totalSums.zusammenfassung.mwstBetrag) }} {{ totalSums.artikelListe[0].waehrung }}</td>
                <td class="text-right"><strong>{{ convertToGermanFormat(totalSums.zusammenfassung.bruttoBetrag) }} {{ totalSums.artikelListe[0].waehrung }}</strong></td>
              </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import useHelper from "@/views/useHelper";

const {
  convertToGermanFormat,
} = useHelper()

export default {
  name: "CostEstimateCard",
  props: {
    totalSums: {
      type: Object,
      required: true
    }
  },
  setup(){
    return {
      convertToGermanFormat
    }
  },
  watch: {
    totalSums: {
      handler(newVal) {
        // handle changes to totalSums here if needed
        console.log("totalSums changed:", newVal);
      },
      deep: true
    }
  }
};
</script>

<style scoped>
  tfoot td{
    font-size:20px !important;
  }

</style>
