<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :label="label"
        hint="d.m.Y format"
        dense
        class="me-3"
        outlined
        hide-details
        clearable
        persistent-hint
        :rules="rules"
        :error-messages="errorMessages"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="selectedDate"
      no-title
      color="primary"
      @input="updateSelectedDate"
      :allowed-dates="allowedDates ? allowedDates : null"
      firstDayOfWeek="1"
      locale="de-DE"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'
import moment from 'moment' // Import moment library for date formatting

export default {
  props: {
    value: {
      type: String,
      default: '',
      required: true
    },
    label: {
      type: String,
      default: '',
      required: true
    },
    rules: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    allowedDates: {
      type: Function,
      default: null,
    },
  },
  setup(props, { emit }) {
    const menu = ref(false)
    const selectedDate = ref(props.value)

    const formattedDate = computed(() => {
      if (!selectedDate.value) return null;
      return moment(selectedDate.value, 'YYYY-MM-DD').format('DD.MM.YYYY');
    });

    const updateSelectedDate = (newDate) => {
      selectedDate.value = newDate; // No need to format here
      emit('input', newDate);
      menu.value = false; // Close the datepicker menu after selecting a date
    }



    return {
      menu,
      formattedDate,
      selectedDate,
      updateSelectedDate,


      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
